export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Welcome to Handle, a daily deal to test your card-play."},{"name":"robots","content":"noindex, nofollow"}],"link":[],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/path2d-polyfill/dist/path2d-polyfill.min.js"},{"type":"text/javascript","children":"var _iub = _iub || [];_iub.csConfiguration = {\"siteId\":3665988,\"cookiePolicyId\":82200802,\"lang\":\"en-GB\"};"},{"type":"text/javascript","src":"https://cs.iubenda.com/autoblocking/3665988.js"},{"type":"text/javascript","src":"//cdn.iubenda.com/cs/gpp/stub.js"},{"type":"text/javascript","src":"//cdn.iubenda.com/cs/iubenda_cs.js","async":true}],"noscript":[],"title":"Handle"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false