import { default as courses8lydY1lLXgMeta } from "/tmp/build_8bd2cefe/pages/courses.vue?macro=true";
import { default as indexX7yIecgn6tMeta } from "/tmp/build_8bd2cefe/pages/index.vue?macro=true";
import { default as login0OuhGV6E7RMeta } from "/tmp/build_8bd2cefe/pages/login.vue?macro=true";
import { default as playil8F5Up6qeMeta } from "/tmp/build_8bd2cefe/pages/play.vue?macro=true";
import { default as handlePkYlR3XrQ7Meta } from "/tmp/build_8bd2cefe/pages/settings/handle.vue?macro=true";
import { default as indexnWhqjrL5WcMeta } from "/tmp/build_8bd2cefe/pages/settings/index.vue?macro=true";
import { default as sign_45upilBrqY5a3IMeta } from "/tmp/build_8bd2cefe/pages/sign-up.vue?macro=true";
import { default as statisticAloyvl6k90Meta } from "/tmp/build_8bd2cefe/pages/statistic.vue?macro=true";
import { default as tournamentREzoX7doinMeta } from "/tmp/build_8bd2cefe/pages/tournament.vue?macro=true";
export default [
  {
    name: courses8lydY1lLXgMeta?.name ?? "courses",
    path: courses8lydY1lLXgMeta?.path ?? "/courses",
    meta: courses8lydY1lLXgMeta || {},
    alias: courses8lydY1lLXgMeta?.alias || [],
    redirect: courses8lydY1lLXgMeta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: indexX7yIecgn6tMeta?.name ?? "index",
    path: indexX7yIecgn6tMeta?.path ?? "/",
    meta: indexX7yIecgn6tMeta || {},
    alias: indexX7yIecgn6tMeta?.alias || [],
    redirect: indexX7yIecgn6tMeta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login0OuhGV6E7RMeta?.name ?? "login",
    path: login0OuhGV6E7RMeta?.path ?? "/login",
    meta: login0OuhGV6E7RMeta || {},
    alias: login0OuhGV6E7RMeta?.alias || [],
    redirect: login0OuhGV6E7RMeta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/login.vue").then(m => m.default || m)
  },
  {
    name: playil8F5Up6qeMeta?.name ?? "play",
    path: playil8F5Up6qeMeta?.path ?? "/play",
    meta: playil8F5Up6qeMeta || {},
    alias: playil8F5Up6qeMeta?.alias || [],
    redirect: playil8F5Up6qeMeta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/play.vue").then(m => m.default || m)
  },
  {
    name: handlePkYlR3XrQ7Meta?.name ?? "settings-handle",
    path: handlePkYlR3XrQ7Meta?.path ?? "/settings/handle",
    meta: handlePkYlR3XrQ7Meta || {},
    alias: handlePkYlR3XrQ7Meta?.alias || [],
    redirect: handlePkYlR3XrQ7Meta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/settings/handle.vue").then(m => m.default || m)
  },
  {
    name: indexnWhqjrL5WcMeta?.name ?? "settings",
    path: indexnWhqjrL5WcMeta?.path ?? "/settings",
    meta: indexnWhqjrL5WcMeta || {},
    alias: indexnWhqjrL5WcMeta?.alias || [],
    redirect: indexnWhqjrL5WcMeta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45upilBrqY5a3IMeta?.name ?? "sign-up",
    path: sign_45upilBrqY5a3IMeta?.path ?? "/sign-up",
    meta: sign_45upilBrqY5a3IMeta || {},
    alias: sign_45upilBrqY5a3IMeta?.alias || [],
    redirect: sign_45upilBrqY5a3IMeta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: statisticAloyvl6k90Meta?.name ?? "statistic",
    path: statisticAloyvl6k90Meta?.path ?? "/statistic",
    meta: statisticAloyvl6k90Meta || {},
    alias: statisticAloyvl6k90Meta?.alias || [],
    redirect: statisticAloyvl6k90Meta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/statistic.vue").then(m => m.default || m)
  },
  {
    name: tournamentREzoX7doinMeta?.name ?? "tournament",
    path: tournamentREzoX7doinMeta?.path ?? "/tournament",
    meta: tournamentREzoX7doinMeta || {},
    alias: tournamentREzoX7doinMeta?.alias || [],
    redirect: tournamentREzoX7doinMeta?.redirect,
    component: () => import("/tmp/build_8bd2cefe/pages/tournament.vue").then(m => m.default || m)
  }
]