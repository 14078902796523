
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "d96ec37d-24a8-465c-841b-36a8496da434"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/tmp/build_8bd2cefe/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
